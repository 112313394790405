import React from 'react'
import { gql } from '@apollo/client'
import { BannerFragment } from './__generated__/banner'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { isNotNull } from '../../../utils/nonNull'
import { HomeBannerMask } from './homeBannerVideo/homeBannerMask'
import { Background, StyledButton, Heading, Subtext, TextContainer, Wrapper } from './homeBannerVideo/styledHomeBanner'

export type BannerProps = {
  data: BannerFragment[]
}


export const Banner: React.VFC<BannerProps> = ({ data }) => (
  <Background>
    <Wrapper>
      {/* @ts-ignore */}
      {data?.[0]?.bannerImage?.[0]?.url && data?.[0]?.mobileImage?.[0]?.url && (
        <HomeBannerMask
          src={isNotNull(data?.[0]?.bannerImage?.[0]?.url) ? data?.[0]?.bannerImage?.[0]?.url : ''}
          //@ts-ignore
          mobileSrc={isNotNull(data?.[0]?.mobileImage?.[0]?.url) ? data?.[0]?.mobileImage?.[0]?.url : ''}
          videoId={data?.[0]?.bannerVideo ? data?.[0]?.bannerVideo : undefined}
        >
          <TextContainer>
            {data?.[0]?.bannerText && (
              //@ts-ignore
              <Heading variant="h2" component={'h1'}>{data?.[0]?.bannerText}</Heading>
            )}
            {data?.[0]?.bannerSubText && (
              <Subtext>{data?.[0]?.bannerSubText}</Subtext>
            )}
            {data?.[0]?.bannerButtonField?.url && (
              <StyledButton className="primary-banner-btn" cta href={data?.[0]?.bannerButtonField?.url}>{data?.[0]?.bannerButtonField?.customText}</StyledButton>
            )}
          </TextContainer>
        </HomeBannerMask>
      )}
    </Wrapper>
  </Background>
)

export const BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment BannerFragment on bannerHome_BlockType {
    bannerText
    bannerSubText
    bannerVideo
    bannerImage {
      width
      height
      ...ImageFragment
    }
    mobileImage {
      width
      height
      ...ImageFragment
    }
    bannerButtonField {
      url
      customText
      target
    }
  }
`
