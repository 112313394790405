import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { Typography } from '@material-ui/core'
import { Button } from '../../../../components/button/button'

export const Background = styled.div`
  background: #f8f8f8;
`

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 0;
  position: relative;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% ${props => props.theme.typography.pxToRem(437)}, 0 100%);
  height: ${props => props.theme.typography.pxToRem(474)};
  display: flex;

  ${props => props.theme.breakpoints.up('lg')} {
    clip-path: polygon(0 0, 100% 0, 100% ${props => props.theme.typography.pxToRem(536)}, 0 100%);
    height: ${props => props.theme.typography.pxToRem(720)};
  }
`

export const Heading = styled(Typography)`
  color: white;
  font-size: ${props => props.theme.typography.pxToRem(30)};
  margin-bottom: ${props => props.theme.typography.pxToRem(17)};
  text-align: center;
  line-height: ${props => props.theme.typography.pxToRem(36)};
  text-shadow: 0 0 ${props => props.theme.typography.pxToRem(16)} rgba(0, 0, 0, 0.25);

  ${props => props.theme.breakpoints.up('lg')} {
    font-size: ${props => props.theme.typography.pxToRem(48)};
    line-height: ${props => props.theme.typography.pxToRem(58)};
    text-align: left;
    margin-bottom: ${props => props.theme.typography.pxToRem(30)};
    max-width: ${props => props.theme.typography.pxToRem(558)};
  }
`

export const Subtext = styled(Typography)`
  color: white;
  margin-bottom: ${props => props.theme.typography.pxToRem(22)};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  text-align: center;
  max-width: ${props => props.theme.typography.pxToRem(290)};
  line-height: ${props => props.theme.typography.pxToRem(22)};
  text-shadow: 0 0 ${props => props.theme.typography.pxToRem(16)} rgba(0, 0, 0, 0.25);
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    max-width: ${props => props.theme.typography.pxToRem(558)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    line-height: ${props => props.theme.typography.pxToRem(35)};
    font-size: ${props => props.theme.typography.pxToRem(22)};
    margin-bottom: ${props => props.theme.typography.pxToRem(47)};
    text-align: left;
  }
`

export const TextContainer = styled.div`
  position: relative;
  max-width: 316px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${props => props.theme.typography.pxToRem(113)};
  margin-top: auto;

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: ${props => props.theme.typography.pxToRem(398)};
    padding-bottom: ${props => props.theme.typography.pxToRem(247)};
    max-width: 1920px;
    width: 100%;
    align-items: baseline;
  }
`

export const StyledButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  padding: ${props => props.theme.typography.pxToRem(18)};
  border-radius: 4px;
  background: #d83b00;

  ${props => props.theme.breakpoints.up('lg')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
    padding: ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(30)};
  }
`

export const VideoBannerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% ${props => props.theme.typography.pxToRem(536)}, 0 100%);
  height: 474px;

  ${props => props.theme.breakpoints.up('lg')} {
    height: ${props => props.theme.typography.pxToRem(720)};
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 474px;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(/images/banner-mob.png);
    width: 100%;
    height: 100%;
    opacity: 0.25;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    height: ${props => props.theme.typography.pxToRem(720)};

    &::after {
      background-image: linear-gradient(90deg, #231f20, rgba(35, 31, 32, 0) 95%);
      opacity: 0.1;
      width: 33%;
    }
  }
`

export const StyledReactPlayer = styled(ReactPlayer)`
  position: relative;

  & > div {
    position: absolute;
    top: -70px;
  }

`

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: 720px;
  display: none;

  ${props => props.theme.breakpoints.up('lg')} {
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(90deg, #231f20, rgba(35, 31, 32, 0) 95%);
    opacity: 0.1;
    width: 33%;
    height: 100%;
  }

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: inset(1px 1px);
    pointer-events: none;
  }

  ${StyledReactPlayer} {
    position: absolute;
    top: 0;
  }
`